import React, { useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showError } from 'utils/toast';
// eslint-disable-next-line import/no-named-default
import { default as authService }from '../constants/services/auth';

export const AuthContext = createContext({
    auth: JSON.parse(sessionStorage.getItem('userData')),
    currencyMasterData: JSON.parse(localStorage.getItem('currencyData')) || [],
    logout: () => {}
});

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [auth, setAuth] = useState(JSON.parse(sessionStorage.getItem('userData')) || sessionStorage.getItem('tempToken'));
    const [currencyMasterData, setCurrencyMasterData] = useState(JSON.parse(localStorage.getItem('currencyData')) || []);

    const logout = async () => {
        try {
            await authService.logout();
            const popupShown = localStorage.getItem('moolahmate_popup_shown');
            sessionStorage.clear();
            localStorage.clear();
            if (popupShown) {
                localStorage.setItem('moolahmate_popup_shown', popupShown);
            }
            navigate('/login', { replace: true });
        } catch (error) {
            showError(t, error);
        };
    };

    return (
        <AuthContext.Provider value={{
            auth, setAuth, currencyMasterData, setCurrencyMasterData, logout
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
