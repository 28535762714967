import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoutes from './components/PrivateRoutes';
import HomePageLayout from './layouts/HomePageLayout';
import DashboardLayout from './layouts/dashboard';
import Page404 from './pages/Page404/Page404';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import HomePage from './pages/home-page/HomePage';
import Login from './pages/login/Login';
import Setup from './pages/onboarding/setup/Setup';
import OnboardingStepper from './pages/onboarding/stepper/Stepper';
import Register from './pages/register/Register';
import './style.css';
import ThemeProvider from './theme';

import AboutUs from './pages/about-us/AboutUs';
import HowItWorks from './pages/how-it-works/HowItWorks';
import MmAndOthers from './pages/mm-and-others/MmAndOthers';

import AuthProvider from './contexts/AuthProvider';
import HomePageSocialMediaLayout from './layouts/landing-social-media/HomePageSocialMediaLayout';
import CalculatorsPage from './pages/calculators/CalculatorsPage';
import DebtRepayment from './pages/calculators/DebtRepayment/DebtRepayment';
import EmbeddedRate from './pages/calculators/EmbeddedRate/EmbeddedRate';
import EmergencyFund from './pages/calculators/EmergencyFund/EmergencyFund';
import FutureValueSaving from './pages/calculators/FutureValueSavings/FutureValueSaving';
import LeasePayment from './pages/calculators/LeasePayment/LeasePayment';
import LoanPayment from './pages/calculators/LoanPayment/LoanPayment';
import MortgagePayment from './pages/calculators/MortgagePayment/MortgagePayment';
import MortgagePrepayment from './pages/calculators/MortgagePrepayment/MortgagePrepayment';
import PresentValue from './pages/calculators/PresentValue/PresentValue';
import ProjectBudget from './pages/calculators/ProjectBudget/ProjectBudget';
import Retirement from './pages/calculators/Retirement/Retirement';
import ReturnOnInvestment from './pages/calculators/ReturnOnInvestment/ReturnOnInvestment';
import SavingsGoal from './pages/calculators/SavingsGoal/SavingsGoal';
import HomeContactUs from './pages/contact-us-home/HomeContactUs';
import ContactUs from './pages/contact-us/ContactUs';
import AllReports from './pages/dashboard/AllReports/AllReports';
import AssetComposition from './pages/dashboard/AssetComposition/AssetComposition';
import DebtPayment from './pages/dashboard/DebtPayment/DebtPayment';
import Discretionary from './pages/dashboard/Discretionary/Discretionary';
import Expense from './pages/dashboard/Expense/Expense';
import FinancialGoals from './pages/dashboard/FinancialGoals/FinancialGoals';
import IncomeExpense from './pages/dashboard/IncomeExpense/IncomeExpense';
import IncomeExpenseSummary from './pages/dashboard/IncomeExpenseSummary/IncomeExpenseSummary';
import CreditCard from './pages/dashboard/Liabilities/CreditCard';
import NetWorth from './pages/dashboard/NetWorth/NetWorth';
import Dashboard from './pages/dashboard/dashboard';
import DownloadedTransactions from './pages/downloaded-transactions/DownloadedTransactions';
import Error from './pages/error/Error';
import FAQ from './pages/faqs/faqs';
import FavoriteWebsites from './pages/favorite-websites/favorite-websites';
import ForumCategory from './pages/forum/ForumCategories';
import ForumTopic from './pages/forum/ForumTopic';
import ForumTopics from './pages/forum/ForumTopics';
import HomePageSocialMedia from './pages/home-page-social-media/HomePageSocialMedia';
import KeyDates from './pages/keydates/KeyDates';
import KnowledgeBaseDetailPage from './pages/knowledge-base/KnowledgeBaseDetailPage';
import KnowledgeBasePage from './pages/knowledge-base/KnowledgeBasePage';
import EmailVerification from './pages/login/EmailVerification';
import VerifyEmail from './pages/login/VerifyEmail';
import EditManualTransactions from './pages/manual-transactions/edit';
import ManualTransactions from './pages/manual-transactions/manual-transactions';
import ViewManualTransactions from './pages/manual-transactions/view';
import NewsroomDetailPage from './pages/newsroom/NewsroomDetailPage';
import NewsroomPage from './pages/newsroom/NewsroomPage';
import FormContextProvider from './pages/onboarding/stepper/context/StepperContext';
import ProfileSummary from './pages/onboarding/summary/ProfileSummary';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import MyProfilePage from './pages/profile/MyProfile';
import Budget from './pages/profile/budget/budget';
import Category from './pages/profile/category/category';
import ChangePassword from './pages/profile/change-password/ChangePassword';
import FinancialInstitution from './pages/profile/financial-institution/financial-institution';
import PersonalInfo from './pages/profile/personal-info/PersonalInfo';
import SubCategory from './pages/profile/sub-category/sub-category';
import ResetPassword from './pages/reset-password/ResetPassword';
import SuggestionBox from './pages/suggestion-box/SuggestionBox';
import TermsAndConditions from './pages/tnc/tnc';
import ToDos from './pages/todos/ToDos';
import Welcome from './pages/welcome/Welcome';
import LoansActivity from './pages/dashboard/LoansActivity/LoansActivity';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import VerifyCode from './pages/verify-code/VerifyCode';
import Payment from './pages/payment/Payment';
import LayoutNoMenu from './layouts/dashboard/LayoutNoMenu';
import Security from './pages/security/Security';
import WhatYouGet from './pages/what-you-get/WhatYouGet';
import Calculators from './pages/calculators/Calculators';
import Subscription from './pages/profile/subscription/Subscription';

export default function App() {
    const [explode, setExplode] = useState(false);

    return (
        <ThemeProvider>
            <AuthProvider>
                <ToastContainer />
                <ErrorBoundary FallbackComponent={Error} onReset={() => setExplode(false)} resetKeys={[explode]}>
                    <Routes>
                        <Route element={<HomePageLayout />}>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/what-you-get" element={<WhatYouGet />} />
                            <Route path="/security" element={<Security />} />
                            <Route path="/how-it-works" element={<HowItWorks />} />
                            <Route path="about-us" element={<AboutUs />} />
                            <Route path="/mmAndOthers" element={<MmAndOthers />} />
                            <Route exact path="/calculators-hub" element={<Calculators />} />
                            <Route exact path="/calculators-hub/list" element={<CalculatorsPage />} />
                            <Route exact path="/calculators-hub/mortgage-payment" element={<MortgagePayment />} />
                            <Route exact path="/calculators-hub/mortgage-prepayment" element={<MortgagePrepayment />} />
                            <Route exact path="/calculators-hub/lease-payment" element={<LeasePayment />} />
                            <Route exact path="/calculators-hub/loan-payment" element={<LoanPayment />} />
                            <Route exact path="/calculators-hub/debt-repayment" element={<DebtRepayment />} />
                            <Route exact path="/calculators-hub/embedded-rate" element={<EmbeddedRate />} />
                            <Route
                                exact
                                path="/calculators-hub/return-on-investment"
                                element={<ReturnOnInvestment />}
                            />
                            <Route exact path="/calculators-hub/future-value-savings" element={<FutureValueSaving />} />
                            <Route exact path="/calculators-hub/emergency-fund" element={<EmergencyFund />} />
                            <Route exact path="/calculators-hub/savings-goal" element={<SavingsGoal />} />
                            <Route exact path="/calculators-hub/retirement" element={<Retirement />} />
                            <Route exact path="/calculators-hub/present-value" element={<PresentValue />} />
                            <Route path="/learn" element={<KnowledgeBasePage />} />
                            <Route path="/learn-detail/:blogId" element={<KnowledgeBaseDetailPage />} />
                            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                            <Route path="/frequently-asked-questions" element={<FAQ />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/newsroom" element={<NewsroomPage />} />
                            <Route path="/news-detail/:newsId" element={<NewsroomDetailPage />} />
                        </Route>
                        <Route element={<HomePageSocialMediaLayout />}>
                            <Route path="/landing" element={<HomePageSocialMedia />} />
                        </Route>
                        <Route element={<PrivateRoutes />}>
                            <Route element={<DashboardLayout />}>
                                <Route path="welcome" element={<Welcome />} />
                                <Route path="contact-us" element={<ContactUs />} />
                                <Route path="key-dates" element={<KeyDates />} />
                                <Route path="favorite-websites" element={<FavoriteWebsites />} />
                                <Route path="todos" element={<ToDos />} />
                                <Route path="/suggestion-box" element={<SuggestionBox />} />
                                <Route path="/downloaded-transactions" element={<DownloadedTransactions />} />
                                <Route path="/manual-transactions" element={<ManualTransactions />} />
                                <Route path="/edit-manual-transactions" element={<EditManualTransactions />} />
                                <Route path="/view-manual-transactions" element={<ViewManualTransactions />} />
                                <Route path="/view-all-templates" element={<ViewManualTransactions />} />
                                <Route exact path="/my-profile" element={<MyProfilePage />} />
                                <Route exact path="/my-profile/personal-info" element={<PersonalInfo />} />
                                <Route
                                    exact
                                    path="/my-profile/link-financial-institutions"
                                    element={<FinancialInstitution />}
                                />
                                <Route exact path="/my-profile/category" element={<Category />} />
                                <Route exact path="/my-profile/change-password" element={<ChangePassword />} />
                                <Route exact path="/my-profile/sub-category" element={<SubCategory />} />
                                <Route exact path="/my-profile/budget" element={<Budget />} />
                                <Route exact path="/my-profile/subscription" element={<Subscription />} />
                                <Route exact path="/calculators" element={<CalculatorsPage />} />
                                <Route exact path="calculators/loan-payment" element={<LoanPayment />} />
                                <Route exact path="calculators/lease-payment" element={<LeasePayment />} />
                                <Route exact path="calculators/mortgage-payment" element={<MortgagePayment />} />
                                <Route exact path="calculators/embedded-rate" element={<EmbeddedRate />} />
                                <Route exact path="calculators/return-on-investment" element={<ReturnOnInvestment />} />
                                <Route exact path="calculators/present-value" element={<PresentValue />} />
                                <Route exact path="calculators/emergency-fund" element={<EmergencyFund />} />
                                <Route exact path="calculators/savings-goal" element={<SavingsGoal />} />
                                <Route exact path="calculators/future-value-savings" element={<FutureValueSaving />} />
                                <Route exact path="calculators/retirement" element={<Retirement />} />
                                <Route exact path="calculators/project-budget" element={<ProjectBudget />} />
                                <Route exact path="calculators/mortgage-prepayment" element={<MortgagePrepayment />} />
                                <Route exact path="calculators/debt-repayment" element={<DebtRepayment />} />
                                <Route exact path="dashboard" element={<Dashboard />} />
                                <Route exact path="dashboard/all-reports" element={<AllReports />} />
                                <Route
                                    exact
                                    path="dashboard/asset-composition"
                                    element={<AssetComposition reportName={'asset-composition'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/liabilities-composition"
                                    element={<AssetComposition reportName={'liabilities-composition'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/line-of-credit"
                                    element={
                                        <CreditCard
                                            apiData="linesofcredit"
                                            reportName={'line-of-credit'}
                                            heading="Status of line of credits usage"
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path="dashboard/liabilities-credit-card"
                                    element={
                                        <CreditCard
                                            apiData="creditcardusage"
                                            reportName={'liabilities-credit-card'}
                                            heading="Status of credit card usage"
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path="dashboard/income-composition"
                                    element={<IncomeExpense reportName={'income-composition'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/expense-composition"
                                    element={<IncomeExpense reportName={'expense-composition'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/income-expense-summary"
                                    element={<IncomeExpenseSummary />}
                                />
                                <Route
                                    exact
                                    path="dashboard/variable-expense"
                                    element={<Discretionary reportName={'variable-expense'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/fixed-expense"
                                    element={<Discretionary reportName={'fixed-expense'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/top-five-expense"
                                    element={<Expense reportName={'top-five-expense'} />}
                                />
                                <Route
                                    exact
                                    path="dashboard/top-ten-expense"
                                    element={<Expense reportName={'top-ten-expense'} />}
                                />
                                <Route exact path="dashboard/financial-goals" element={<FinancialGoals />} />
                                <Route exact path="dashboard/net-worth" element={<NetWorth />} />
                                <Route exact path="dashboard/debt-payment-report" element={<DebtPayment />} />
                                <Route exact path="dashboard/loans-activity" element={<LoansActivity />} />
                                <Route path="/forum-categories" element={<ForumCategory />} />
                                <Route path="/forum-topics/category/:categoryId" element={<ForumTopics />} />
                                <Route path="/forum-topics/tag/:tag" element={<ForumTopics />} />
                                <Route path="/forum-topics/topic/:topicId" element={<ForumTopic />} />
                                <Route path="/knowledge-base" element={<KnowledgeBasePage />} />
                                <Route path="/knowledge-base-detail/:blogId" element={<KnowledgeBaseDetailPage />} />
                                <Route path="/faqs" element={<FAQ />} />
                                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                                <Route path="/subscribe" element={<Payment />} />
                            </Route>
                            <Route element={<LayoutNoMenu />}>
                                <Route path="/setup" element={<Setup />} />
                                <Route
                                    path="/onboarding"
                                    element={
                                        <FormContextProvider>
                                            <OnboardingStepper />
                                        </FormContextProvider>
                                    }
                                />
                                <Route path="/profile-summary" element={<ProfileSummary />} />
                                <Route path="/payment" element={<Payment />} />
                            </Route>
                        </Route>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/contact-us-home" element={<HomeContactUs />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/user/verify" element={<VerifyEmail />} />
                        <Route path="/email-verification" element={<EmailVerification />} />
                        <Route path="/verify-code" element={<VerifyCode />} />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </ErrorBoundary>
            </AuthProvider>
        </ThemeProvider>
    );
}
