import { Button, Grid, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import { HeaderStyle, LogoStyle, RootStyle, SectionStyle } from './styled-components';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function Error({ error, resetErrorBoundary }) {
    const { t } = useTranslation();
    return (
        <Page title={t('error.title')}>
            <RootStyle>
                <SectionStyle />
                <Grid container direction="row" spacing={2} alignItems="baseline">
                    <HeaderStyle item xs={12} sm={6}>
                        <LogoStyle>
                            <Logo sx={{ width: 430, height: 'auto' }} />
                        </LogoStyle>
                    </HeaderStyle>
                    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Typography variant="h3" paragraph style={{ textAlign: 'left' }}>
                            {t('error.error-message')}
                        </Typography>
                        <pre style={{ textAlign: 'left' }}>{error.stack}</pre>
                        <br />
                        <Button size="large" variant="contained" onClick={() => resetErrorBoundary()}>
                            {t('error.try-again')}
                        </Button>
                    </ContentStyle>
                </Grid>
            </RootStyle>
        </Page>
    );
}
