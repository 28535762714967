import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DialogAtom({
    maxWidth = 'lg',
    open,
    dialogTitle = '',
    content,
    onDialogAction,
    isSaveDisabled = false,
    additionalButtons = '',
    hideSave = false,
    saveButtonLabel = null,
    isLoading = false,
    cancelButtonLabel = null,
    hideCancelButton = false,
}) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {dialogTitle && (
                <DialogTitle id="alert-dialog-title">
                    <Typography className="titleStyle" variant="h4">
                        {dialogTitle}
                    </Typography>
                </DialogTitle>
            )}
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                {additionalButtons}
                {!hideCancelButton && (
                    <LoadingButton
                        type="submit"
                        onClick={() => onDialogAction('cancel')}
                        className="loadingButtonStyle1"
                    >
                        {cancelButtonLabel || t('Cancel')}
                    </LoadingButton>
                )}
                {!hideSave && (
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        onClick={() => {
                            onDialogAction('save');
                        }}
                        // autoFocus
                        disabled={isSaveDisabled}
                        className="loadingButtonStyle2"
                    >
                        {saveButtonLabel || t('common-translation.Save')}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
