import image from 'assets/image';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Banner from './banner/Banner';
import ClientReview from './clientReview/ClientReview';
import GetMore2 from './getMore/GetMore2';
import GetStarted from './getStarted/GetStarted';
import MoolahVideoDetails from './moolahVideoDetails/MoolahVideoDetails';
import Dialog from '../../components/dialog/Dialog';
import './style.css';
import WhyLoveMoolahMate from './whyLoveMoolahMate/WhyLoveMoolahMate';

export default function HomePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { hash } = location;
    const [showPopup, setShowPopup] = useState(false);
    const [timeLeft, setTimeLeft] = useState(10);

    useEffect(() => {
        if (hash === '#reviews') {
            document.querySelector(`#reviews`).scrollIntoView();
        }
        if (hash === '#pricing') {
            document.querySelector(`#pricing`).scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    useEffect(() => {
        // Check if user has seen the popup before
        const hasSeenPopup = localStorage.getItem('moolahmate_popup_shown');

        if (!hasSeenPopup) {
            const showTimer = setTimeout(() => {
                setShowPopup(true);
                // Mark popup as shown
                localStorage.setItem('moolahmate_popup_shown', 'true');
            }, 3000);

            const hideTimer = setTimeout(() => {
                setShowPopup(false);
            }, 13000);

            return () => {
                clearTimeout(showTimer);
                clearTimeout(hideTimer);
            };
        }
    }, []);

    useEffect(() => {
        let timer;
        if (showPopup && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setShowPopup(false);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [showPopup, timeLeft]);

    useEffect(() => {
        if (showPopup) {
            setTimeLeft(10);
        }
    }, [showPopup]);

    const handleDialogAction = (action) => {
        if (action === 'save') {
            window.open('https://www.moneysense.ca', '_blank'); // Replace with actual article URL
        } else if (action === 'cancel') {
            navigate('/register'); // Replace with actual signup route
        } else if (action === 'close') {
            setShowPopup(false);
        }
        setShowPopup(false);
    };

    const popupContent = (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                Discover why MoneySense magazine named MoolahMate <strong>"Best overall financial app"</strong> for
                Canada for 2024. Or better yet, sign up for the MoolahMate app and enjoy a first-hand experience!
            </p>
            <img
                src={image.moneySense}
                alt="MoneySense Logo"
                style={{
                    height: '40px',
                    margin: '0 auto',
                    display: 'block',
                }}
            />
        </div>
    );

    const closeButton = (
        <LoadingButton onClick={() => handleDialogAction('close')} className="loadingButtonStyle1">
            {`Close (${timeLeft}s)`}
        </LoadingButton>
    );

    return (
        <Page
            title={'MoolahMate: Your Personal Money Manager Toolkit Made Easy'}
            description={
                'Looking for an app to manage your finances that is easy to use? MoolahMate is here to help you with your daily and long-term financial outlook. Sign up now'
            }
        >
            <Dialog
                open={showPopup}
                content={popupContent}
                onDialogAction={handleDialogAction}
                saveButtonLabel="Read The Article"
                cancelButtonLabel="Sign Up Today"
                maxWidth="sm"
                additionalButtons={closeButton}
            />
            <Banner />
            <GetMore2 imageUrl={image.footerGraphicAnimation} orderType={''} />
            <MoolahVideoDetails />
            <WhyLoveMoolahMate />
            <GetStarted />
            {/* <DataSecurityPrivacy/> */}
            {/* <EverythingYouNeed /> */}
            {/* <MateMoolahMate/> */}
            {/* <GetYourFinancial/> */}
            <ClientReview />
            {/* <MoolahMateCalculators/> */}
            {/* <InsightfulReporting/>
            <SliderBlog/>
            <GetMore imageUrl={image.footerGraphicAnimation} orderType={''}/> */}
        </Page>
    );
}
